

<template>
  <div id="create">
    <div class="create_inner">
      <div class="right">
        <span v-if="$route.name == 'create'">
          已有账户,<router-link class="active-color" to="/login/1"
            >马上登录</router-link
          >
        </span>
        |
        <router-link class="active-color back" to="/login/1"
          >返回登录首页</router-link
        >
      </div>
      <div class="inner">
        <step-template
          :data="$route.name == 'create' ? steps : steps1"
          class="step"
          :routeIndex="index"
        ></step-template>
        <div class="login">
          <step-group @changeIndex="changeIndex" :index="index"></step-group>
        </div>
      </div>
    </div>
    <div class="footer">
      Copyright © &nbsp;2020 数智工场科技产业有限公司出品
    </div>
  </div>
</template>

<script>
import StepGroup from "./register/index.vue";
import { StepTemplate } from "@/components/login/index";
export default {
  data() {
    return {
      index: 0,
      steps: [
        {
          title: "确认手机号",
        },
        {
          title: "填写账户信息",
        },
        {
          title: "注册完成",
        },
      ],
      steps1: [
        {
          title: "确认手机号",
        },
        {
          title: "修改密码",
        },
        {
          title: "修改完成",
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.query.step) {
          this.index = this.$route.query.step - "";
        }
      },
    },
  },
  mounted() {
    this.index = this.$route.query.step - "";
  },
  components: {
    StepTemplate,
    StepGroup,
  },
};
</script>

<style lang="scss" scoped>
#create {
  height: 93%;
  // position: relative;
}
.create_inner {
  width: 97%;
  margin: 0 auto;
  background: #fff;
  padding: 32px;
  box-sizing: border-box;
  height: 93%;
  margin-top: 24px;
  .right {
    font-size: 14px;
    .active-color:first-child {
      margin-right: 18px;
    }
    .back {
      margin-left: 18px;
    }
  }
  .inner {
    width: 824px;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login {
    width: 52%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }
}
</style>