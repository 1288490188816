<template>
  <div id="step2">
    <el-form
      ref="stepForm"
      :model="ruleForm"
      :rules="rules"
      label-width="200px"
    >
      <el-form-item label="账号：" prop="phone">
        <phone-template @getPhone="getPhone" :disable="disable" :isMore="true">
        </phone-template>
      </el-form-item>
      <el-form-item prop="code">
        <code-template
          @codeVerify="codeVerify"
          :isIcon="false"
          :phone="ruleForm.phone"
          @getCode="getCode"
        ></code-template>
      </el-form-item>
      <el-form-item label="输入密码：" prop="password">
        <password-template
          :placeholder="'8-20位密码，字母/数字/符号'"
          :isIcon="false"
          @getPassword="getPassword"
        >
        </password-template>
      </el-form-item>
      <el-form-item label="再次输入密码：" prop="passwordAgain">
        <password-template
          :placeholder="'8-20位密码，字母/数字/符号'"
          :isIcon="false"
          @getPassword="getPasswordAgain"
        >
        </password-template>
      </el-form-item>
    </el-form>
    <div class="login-msg">
      <div class="login-button login-input-item" @click="next">下一步</div>
      <div class="other-login" v-if="$route.name == 'create'">
        <span class="other-login-txt">使用社交账号注册</span>
        <span class="wx-login-icon"></span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  StepTemplate,
  PhoneTemplate,
  CodeTemplate,
  PasswordTemplate,
} from "@/components/login/index.js";
import { register, resetPassword } from "@/api/user";
export default {
  data() {
    return {
      disable: true,
      click: false,
      flag: true,
      phone: "",
      ruleForm: {
        phone: "",
        password: "",
        code: "",
        passwordAgain: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3,5,7,9,8]{1}\d{9}$/,
            message: "手机号格式有误",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码" }],
        password: [
          { required: true, message: "请输入密码" },
          { min: 8, max: 20, message: "长度在8到20个字符" },
        ],
        passwordAgain: [
          { required: true, message: "请输入密码" },
          { min: 8, max: 20, message: "长度在8到20个字符" },
          {
            validator: (rule, value, callback) => {
              if (value != this.ruleForm.password) {
                return callback(new Error("两次输入的密码不一致"));
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  destroyed() {
     sessionStorage.removeItem("step1");
  },
  methods: {
    getCode(val) {
      this.ruleForm.code = val;
      this.flag = true;
    },
    getPhone(val) {
      this.ruleForm.phone = val;
    },
    getPassword(val) {
      this.ruleForm.password = val;
      this.flag = true;
    },
    getPasswordAgain(val) {
      this.ruleForm.passwordAgain = val;
      this.flag = true;
    },
    codeVerify(callback) {
      this.$refs.stepForm.validateField("phone", (result) => {
        if (!result) {
          callback();
          return;
        }
      });
    },

    next() {
      if (this.flag) {
        this.flag = false;
        this.$refs.stepForm.validate((bool, obj) => {
          if (bool) {
            const code =localStorage.getItem('code');
            if (!code) {
              this.$message({
                message: "验证码已失效，请重新获取",
                type: "error",
              });
              return;
            }
            if (this.ruleForm.code != Base64.decode(code)) {
              this.$message({
                message: "验证码输入有误",
                type: "error",
              });
              return;
            }
            if (this.$route.name == "create") {
              register({
                phone: this.ruleForm.phone,
                password: this.ruleForm.password,
              }).then((res) => {
                if (res && res.status == 200) {
                  sessionStorage.removeItem("code");
                  sessionStorage.removeItem("step1");
                  setCookie(
                    "userInfo",
                    JSON.stringify({
                      name: res.data.name,
                      avatar: res.data.avatar,
                    })
                  );
                  this.$router.replace({
                    path: "/create",
                    query: { step: 3, token: Base64.encode(res.data.token) },
                  });
                }
              });
            } else {
              resetPassword({
                phone: this.ruleForm.phone,
                password: this.ruleForm.password,
                createTime: new Date().getTime(),
              }).then((res) => {
                if (res && res.status == 200) {
                  sessionStorage.removeItem("code");
                  sessionStorage.removeItem("step1");
                  // setCookie("token", Base64.encode(res.data.token));
                  this.$router.replace({
                    path: "/resetPassword",
                    query: { step: 3},
                  });
                }
              });
            }
          }
        });
      }
    },
  },
  components: {
    PhoneTemplate,
    PasswordTemplate,
    CodeTemplate,
  },
};
</script>

<style lang="scss" scoped>
#step2 {
  .login-msg {
    margin-left: 120px;
  }
  .login-button {
    width: 74px;
    background: #00a0e9;
    color: #fff;
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
    box-shadow: 0 17px 42px -12px rgba(10, 169, 238, 0.3);
    border-radius: 2px;
    margin: 32px 0 25px;
  }
  .login-checkbox {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.85);
  }
  .other-login {
    font-size: 14px;
    color: rgba(85, 97, 126, 1);
    .wx-login-icon {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-left: 16px;
      background: url("../../..//assets/images/login_wx_icon.png") center center;
      background-size: 100% 100%;
      vertical-align: middle;
    }
    .wx-login-icon:hover {
      background: url("../../../assets/images/login_wx.png") center center;
      background-size: 100% 100%;
    }
  }
  .disable {
    opacity: 0.5;
  }
  /deep/.el-form-item__label{
    width: 120px !important;
  }
   /deep/.el-form-item__content{
     margin-left: 120px !important;
  }
}
</style>